import React, {useState, useEffect} from "react";
import ProjectSample from "../project_samples/ProjectSamples";

import socialDev from "../image/socialDev.png"
import xp1Webdev from "../image/xp1Webdev.png"
import satellite from '../image/satellite.png'
import golista from '../image/golista.png'
import inflow from '../image/inflow.png';
import awards from '../image/awards.png'
import resumAgent from '../image/resumeAgentGif.png'
import financialAgent from '../image/financialAgent.png'
// import Footer from "../components/footer/Footer";


const Projects = () => {
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (!isMobile) {
      const section = document.querySelector('.section-project-description');

  
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            section.classList.add('fade-out');
          } else {
            section.classList.remove('fade-out');
          }
        });
      });
  
      observer.observe(section);
  
      return () => observer.disconnect();
    }
  }, [isMobile]);
  // console.log("mobile", isMobile)


  const projectData = [
    {
      title: "Golista!",
      description:
        "Golista uses an advanced AI agent to generate ideas and kickstart your projects quickly. Whether you're setting personal goals, organizing tasks, or brainstorming new ventures, Golista provides personalized suggestions tailored to your needs. Start your journey with Golista today!",
      features: [
        "Goal-Based Idea Generation:<br /> Golista generates ideas to help you kickstart your projects quickly.",
        "ChatGPT Integration:<br /> The app uses advanced AI agents to provide specific and detailed suggestions tailored to your goals.",
        "User-Friendly Interface:<br /> Golista offers a clean and intuitive interface to ensure a smooth user experience.",
      ],
      technical: [
        "Front-End:<br /> Developed using modern web technologies to create a responsive and interactive user interface.",
        "Back-End:<br /> Integrates the ChatGPT language model to generate personalized suggestions based on user input.",
        "API Integration:<br /> Integrates with the OpenAI API to generate innovative ideas and valuable suggestions.",
        
      ],
      imageUrl: golista,
      projectUrl: "https://apps.apple.com/us/app/golista/id6450496916",
    },
    {
      title: "A.I. Financial Agent",
      description:
        "Financial Agent is your premier AI solution for generating comprehensive financial reports. Utilizing cutting-edge AI technology, Financial Agent gathers and analyzes key company KPIs to deliver insightful and detailed financial assessments, helping you make informed decisions in a competitive market.",
      features: [
        "AI-Driven Insights:<br /> Leveraging advanced AI frameworks, Financial Agent dynamically sources and analyzes company data, providing interactive and insightful financial reports.",
        "Comprehensive Analysis:<br /> AI agents perform detailed evaluations and generate reports that include a full financial analysis along with a summary of key insights and metrics.",
        "Adaptive Layout:<br /> Ensures a seamless and optimal experience across all devices, from smartphones to tablets, for consistent access to financial insights.",
      ],
      technical: [
        "Next.js/Typescript Front-End:<br /> The application uses a Next.js TypeScript frontend hosted on Vercel, offering a smooth and responsive user experience for financial reporting.",
        "Flask/Python Back-End:<br /> Backed by a Flask-based backend hosted on Heroku, Financial Agent efficiently processes and manages financial data and requests.",
      ],
      imageUrl: financialAgent,
      projectUrl: "https://ai-financial-agent.vercel.app/",
    },
    {
      title: "XP1: Your Ultimate Expense Tracking Companion",
      description:
        "XP1 is a powerful and user-friendly mobile app designed to help you take control of your finances. Developed using React Native, XP1 offers a seamless user experience and robust features to help you manage your spending like a pro.",
      features: [
        "Weekly Summaries:<br /> Receive summaries of your weekly spending for effortless financial tracking.",
        "Interactive Charts:<br /> Gain insights into your spending habits with intuitive and interactive charts that make understanding your finances a breeze.",
      ],
      technical: [
        "Node.js REST API:<br /> Developed RESTful API routes on a Heroku-hosted server, using Mongoose models for robust data validation.",
        "Responsive Design:<br /> Utilized React Native stylesheets to create a visually appealing and responsive app.",
        "Email Notifications:<br /> Integrated Nodemailer for sending custom-styled email notifications for new user registrations.",
        "Protected Routes:<br /> Secured server routes for handling PUT, DELETE, GET, and POST requests.",
      ],
      imageUrl: xp1Webdev,
      projectUrl: "https://apps.apple.com/us/app/xp1/id6470176329",
    },
    {
      title: "Resume Agent",
      description:
        "Resume Agent is your go-to AI app for crafting exceptional resumes tailored to any job description. Leveraging the power of advanced AI, ResumAgent helps you stand out in the competitive job market.",
      features: [
        "AI-Driven Insights:<br /> CrewAI framework for orchestrating role-playing, autonomous AI agents, allows for dynamic and interactive resume-building sessions.",
        "Comprehensive Analysis:<br /> Revision and writing is performed by AI Agents. A report is generated having 'Revised Resume' and 'Summary of Changes'",
        "Adaptive Layout:<br /> Delivers a consistent and optimal user experience across all devices, from smartphones to tablets.",
      ],
      technical: [
        "Next.js/Typescript Front-End:<br /> The app features a Next.js Typescript frontend hosted on Vercel, ensuring a smooth and responsive user experience.",
        "Flask/Python Back-End:<br /> Powered by a Flask backend hosted on Heroku, ResumAgent efficiently handles and processes your requests.",
      ],
      imageUrl: resumAgent,
      projectUrl: "https://ai-research-agent.vercel.app/",
    },
    {
      title: "InFlow: Comprehensive Invoice Software",
      description:
        "InFlow was developed using Next.js and PostgreSQL, and is designed to simplify your invoicing process, making it easier to manage your revenue and customer data effectively. Start streamlining your invoicing today with InFlow!",
      features: [
        "Revenue Summary:<br /> See the amounts for total collected, total pending.",
        "Add customers to your database:<br /> Create new customers in your database.",
        "Create Invoice Record:<br /> Assign invoices to the customers in your database.",
      ],
      technical: [
        "Developed with Next.js:<br /> Leverages built-in Next.js features like context() state management and auth() access management.",
        "Back-End:<br /> Using a PostgreSQL database and Next.js Server Actions to handle server-side logic.",
      ],
      imageUrl: inflow,
      projectUrl: "https://nextjs2-beta-jet.vercel.app/",
    },
    {
      title: "Social Dev",
      description:
        "Join a community where developers share github repos and discuss programming topics!",
      features: [
        "Profile Creation:<br /> Easily create and manage your developer profile, showcasing your skills and projects.",
        "Github Integraiton:<br /> Display your GitHub repositories directly on your profile, allowing others to view and access your work effortlessly.",
        "Post Sharing:<br /> Share posts and updates with the developer community, and stay engaged with the latest trends and discussions.",
      ],
      technical: [
        "State Management:<br /> Utilized Redux for efficient global state management, ensuring a responsive user interface.",
        "Front-End:<br /> Developed using JavaScript, HTML, and CSS with the React framework.",
        "Back-End:<br /> Built and maintained a robust Node.js server and REST API, enabling reliable CRUD operations on a MongoDB database through Mongoose models.",
      ],
      imageUrl: socialDev,
      projectUrl: "https://thesocialdev-21a01ec76f8c.herokuapp.com/",
    },
    {
      title: "Satellite Tracker",
      description:
        "This website tracks the International Space Station (ISS) in real-time. The application uses Flask for the front-end and Python for the back-end. By sending an API request and parsing the response data, it places the ISS icon on a map using its coordinates and displays its current velocity.",
      features: [
        "Real-Time Tracking:<br /> Continuously monitor the International Space Station's position with live updates.",
        "Velocity Display:<br /> See the current speed of the ISS displayed in real-time.",
        "Accurate Data:<br /> Integrates with a reliable API to provide precise and up-to-date tracking information.",
      ],
      technical: [
        "Coordinate Mapping:<br /> Utilizes a mapping library (such as Leaflet.js or Google Maps API) to plot the ISS's location on a map.",
        "Real-Time Data Updates:<br /> Employs periodic polling to keep the ISS position and velocity information current.",
      ],
      imageUrl: satellite,
      projectUrl: "https://whereissat-d141decca63a.herokuapp.com/",
    },
  ];
  return (
    <>
      <div className="Content">
        {isMobile ? (
          <div className="lower-section">
            <div className="upper-section2">
              <img className="headshot" src={awards} alt={awards} />
              <h1 className="tossing">
                Julio Daniel<h3>React Developer</h3>
                <h6 className="job-title">
                  Full Stack Web Developer | Mobile Applications{" "}
                </h6>
              </h1>
            </div>
            <p className="project-description2">
              Looking for a cutting-edge website or mobile app? I specialize in
              creating stunning, responsive websites and mobile apps tailored to
              your needs. Explore my portfolio to see how I integrate AI agents
              and the latest technology trends into every project, ensuring
              innovative, high-quality, and user-friendly solutions. Let’s build
              your digital presence with the best in web and mobile development.
            </p>
            <div className="lower-section2">
              {projectData.map((project, index) => (
                <ProjectSample key={index} project={project} />
              ))}
            </div>
          </div>
        ) : (
          <>
            <div className="lower-section">
              <div className="upper-section2">
                <img className="headshot" src={awards} alt={awards} />
                <h1 className="tossing">
                  Julio Daniel<h3>React Developer</h3>
                  <h6 className="job-title">
                    Full Stack Web Developer | Mobile Applications{" "}
                  </h6>
                </h1>
              </div>
            </div>
            <div className="Content2">
              <section className="section-project-description">
                <p className="project-description2">
                  Looking for a cutting-edge website or mobile app? I specialize
                  in creating stunning, responsive websites and mobile apps
                  tailored to your needs. Explore my portfolio to see how I
                  integrate AI agents and the latest technology trends into
                  every project, ensuring innovative, high-quality, and
                  user-friendly solutions. Let’s build your digital presence
                  with the best in web and mobile development.
                </p>
              </section>
            </div>
        <div className="lower-section2">
          {projectData.map((project, index) => (
            <ProjectSample key={index} project={project} />
          ))}
        </div>
          </>
        )}
      </div>
    </>
  );
};

export default Projects;
