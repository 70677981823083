import React, { useState } from "react";
import duck from "../image/duck.png";
import ecom from '../image/ecom.png'
import marketing from '../image/upcoming.png'
import finagent from '../image/financialAgent.png'
import { Puff } from "react-loading-icons";


const Shop = () => {
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const Cart = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    function toggleExpand() {
      setIsExpanded(!isExpanded);
    }

    return (
      <>
        {!isExpanded ? (
          <div className="fixed-bottom-right-container">
            <button
              type="button"
              className="fixed-bottom-right-button"
              onClick={toggleExpand}
            >
              Review Cart
            </button>
          </div>
        ) : (
          <div className="expanded-cart">
            <h5>Your Cart</h5>
            {cart.map((items) => (
              <>
                <div className="cart-line-item" key={items.id}>
                  <p>Item:{items.name}</p>
                  <p>
                    Amount: $
                    {parseFloat(items.price.replace(/[^0-9.-]+/g, "")) *
                      items.quantity}
                  </p>
                  <p>Qty:{items.quantity}</p>
                  <img
                    src={items.image}
                    height={80}
                    width={80}
                    alt={items.name}
                  />
                </div>
                {"________________"}
              </>
            ))}
            <form onSubmit={(e) => handleSubmit(e, cart)}>
              <div className="cart-buttons">
                <button className="checkout-buttons2" type="submit">
                  Checkout Cart
                </button>
                <button className="checkout-buttons2" onClick={toggleExpand}>
                  Close
                </button>
              </div>
            </form>
          </div>
        )}
      </>
    );
  };

  async function handleSubmit(event, cart) {
    event.preventDefault();
    console.log("Cart being sent:", JSON.stringify(cart, null, 2));
    setLoading(true);

    try {
      const response = await fetch(
        "https://stripe-server-jd-8679a3ea8824.herokuapp.com/create-checkout-session",
        // "http://localhost:4242/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cart }),
        }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const session = await response.json();
      window.location.href = session.url; // Redirect to Stripe checkout
      // setLoading(false)
    } catch (error) {
      console.error("Error", error);
      alert("There was an error processing your request. Please try again.");
      setLoading(false);
    }
  }

  const items = [
    {
      id: 1,
      name: "Debug Duck",
      image: duck,
      price: "$5.00",
      description:
        "A rubber duck for debugging. Describe the problem to your duck to understand the problem.",
    },
    {
      id: 2,
      name: "E-Commerce Website",
      image: ecom,
      price: "$250.00",
      description:
        "A complete e-commerce site with company branding, shopping cart, and secure checkout.",
    },
    {
      id: 3,
      name: "Marketing Website",
      image: marketing,
      price: "$150.00",
      description:
        "A marketing website to showcase your brand, engage visitors, and drive conversions.",
      // projectUrl: "https://nextjschimney.vercel.app/",
    },
    {
      id: 4,
      name: "A.I. Website",
      image: finagent,
      price: "$350.00",
      description:
        "A website that integrates A.I. Agents for various purposes, like finance or writing.",
      projectUrl: "https://ai-financial-agent.vercel.app/",
    },
  ];

  const addToCart = (item) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((cartItem) => cartItem.id === item.id);
      if (existingItem) {
        return prevCart.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      } else {
        return [...prevCart, { ...item, quantity: 1 }];
      }
    });
  };

  const removeFromCart = (id) => {
    setCart((prevCart) =>
      prevCart
        .map((item) =>
          item.id === id
            ? { ...item, quantity: Math.max(item.quantity - 1, 0) }
            : item
        )
        .filter((item) => item.quantity > 0)
    );
  };

  return (
    <>
      <div className="Content">
        <div className="upper-section">
          <h1 className="tossing">Level Up Your Brand: Shop Now!</h1>
          <h1 className="project-description3">
            Explore items in our shop! Choose from our expertly crafted
            e-commerce and marketing websites, or grab a fun rubber duck.
            Perfect for your business or a playful gift!
          </h1>
          <h5>Physical items are available for local pick-up in LA Area*</h5>
          {loading && (
            <>
              <Puff stroke="#0070f3" />
              <h2>Loading Checkout</h2>
            </>
          )}
        </div>
        <section className="sale-items">
          {items.map((item) => (
            <div className="project-sampler1">
              <div key={item.id}>
                <form id="contact-form" className="contact-form" method="post">
                  <a
                    href={item.projectUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="project-link"
                  >
                    <img
                      src={item.image}
                      height={320}
                      width={320}
                      alt={item.name}
                    />
                  </a>
                  <h5>{item.name}</h5>
                  <h5>{item.price}</h5>
                  <p3>{item.description}</p3>
                  <div className="checkout-buttons">
                    <button
                      type="button"
                      onClick={() => removeFromCart(item.id)}
                    >
                      -
                    </button>{" "}
                    <input
                      className="qty"
                      type="text"
                      value={
                        cart.find((cartItem) => cartItem.id === item.id)
                          ?.quantity || 0
                      }
                      readOnly
                    />{" "}
                    <button type="button" onClick={() => addToCart(item)}>
                      +
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ))}
        </section>
        <Cart />
      </div>
    </>
  );
};

export default Shop;